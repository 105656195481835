// @flow
import type { FactType, LinkType } from 'UI/Section/Scenario'

export const facts: Array<FactType> = [
  {
    label: 'Role',
    role: 'UX/UI Designer',
  },
  {
    label: 'Duration',
    role: '8 Weeks',
  },
  {
    label: 'Project',
    role: 'Job Recruitment University Project',
  },
];

export const links: Array<LinkType> = [
  {
    id: 0,
    url: 'https://www.figma.com/proto/RnwuNrXcNmfHBFrqXgUqSx/Steady-Paycheck-Agency%3A-Prototype?page-id=100%3A13933&node-id=142-21204&viewport=2287%2C-4467%2C0.48&t=rnSHNJEqM8WuyvGZ-1&scaling=scale-down&content-scaling=fixed&starting-point-node-id=105%3A14159&show-proto-sidebar=1',
    title: 'Launch Demo',
  },
];