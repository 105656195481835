// @flow
import * as React from 'react';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { Reorder } from 'framer-motion';

import useComponent from 'react-hooks/useComponent';
import useTheme from 'react-hooks/useTheme';

import { Col, Container } from 'Grid';
import Layout, { LAYOUT_VARIANTS } from 'Layout';

import { HEADING_VARIANTS } from 'Shared-UI/Headings';

import Section, { SECTION_VARIANTS } from 'UI/Section';
import Tabs from 'UI/Tabs';
import Project, { PROJECT_VARIANTS } from 'UI/Project';
import { HERO_VARIANTS } from 'UI/Hero';

import { maintags } from 'Pages';
import { filterProjects, renderProject } from './utils';
import { projects } from './projects';

function Main(): React.Node {
  const navigate = useNavigate();
  const { toggle } = useComponent();
  const { darkMode } = useTheme();
  const projectsRef: React.RefObject<HTMLDivElement | null> = useRef<HTMLDivElement | null>(null);

  const [items, setItems] = useState(projects);
  const [hoverState, setHoverState] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('All');

  const {
    filterItems,
    handleReorder,
    getFilteredItems,
    checkCategory,
    checkCategoryEnter,
  } = filterProjects(
    setSelectedCategory,
    selectedCategory,
    setHoverState,
    hoverState,
    setItems,
    items
  );

  return (
    <Layout
      variant={LAYOUT_VARIANTS.PRIMARY}
      meta={{
        title: 'Alex Zietara Nicholls',
        tags: maintags,
      }}
      hero={{
        variant: HERO_VARIANTS.LANDING,
      }}
    >
      <Container>
        <div
          ref={projectsRef}
          className='projects flex flex-col justify-center items-center gap-7 lg:gap-9'
        >
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'All Design Projects',
                subtitle: 'Projects',
              },
              showSubtitle: true,
              className: '[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />

          <div className='flex flex-col gap-7 w-full'>
            <div className='flex flex-row items-center justify-center w-full'>
              <Tabs filterProjects={filterItems} />
            </div>
            <Reorder.Group
              axis='y'
              className='grid gap-4 w-full md:grid-cols-4 xl:grid-cols-6'
              values={getFilteredItems()}
              onReorder={handleReorder}
            >
              {getFilteredItems().map((item) => (
                <Col
                  key={item.id}
                  value={item}
                  onClick={() => navigate(item.button.link)}
                  drag={false}
                  className={`rounded-3xl w-full h-[500px] xl:row-span-2 ${toggle(
                    item.className.light,
                    item.className.dark
                  )} ${checkCategory(item)}`}
                  animated
                  col={item.col}
                >
                  <Project
                      render={renderProject(item, darkMode)}
                      project={item}
                      onMouseEnter={() => checkCategoryEnter(item)}
                      onMouseLeave={() =>
                        setHoverState({ ...hoverState, [item.id]: false })
                      }
                      hovered={hoverState[item.id] || false}
                    />
                </Col>
              ))}
            </Reorder.Group>
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Main;
