// @flow
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
export { default as Main } from './Main';
export { default as Login } from './Login';
export { Bubbly, SteadyPayCheck, Techrehab } from './Projects';

type MetaTagType = {
  id: number,
  name: string,
  content: string,
}

type MetaProps = {
  tags: Array<MetaTagType>,
  title: string
}

type MetaType = (props: MetaProps) => React.Node;


export const maintags: Array<MetaTagType> = [
  {
    id: 0,
    name: 'description',
    content:
      'UX UI Designer whos aim is to design beautiful interfaces from simplicity to satisfaction.',
  },
];

export const bubblytags: Array<MetaTagType> = [
  {
    id: 0,
    name: 'description',
    content:
      'Bubbly revolutionizes resource conservation via IoT-enabled smart meters, retrofitted to existing showers. Delivering real-time data on costs, water usage, electricity consumption, thus driving sustainability and substantial savings for its clients.',
  },
];

export const steadypaychecktags: Array<MetaTagType> = [
  {
    id: 0,
    name: 'description',
    content:
      'Steady Paycheck Agency, a job recruitment platform bridges the gap between job seekers and employers. The goal is to create an efficient platform that nurtures connections, fosters career development, and ensures long-term job satisfaction.',
  },
];

export const techrehabtags: Array<MetaTagType> = [
  {
    id: 0,
    name: 'description',
    content:
      'TechRehab computer repair company faces a challenge with outdated branding and an obsolete online presence. They seek a comprehensive rebranding and solution to modernise their identity, enhance their online visibility, and improve user engagement.',
  },
];


export const ncoffeetags: Array<MetaTagType> = [
  {
    id: 0,
    name: 'description',
    content:
      'N.Coffee is a mobile barista service in Chichester, helping on-the-go customers order and collect coffee with ease from various locations. Discover a convenient, location-based coffee experience with N.Coffee’s innovative mobile solution.',
  },
];



export const Meta: MetaType = ({ tags, title }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {tags.map((tag) => {
        return (
          <meta key={tag.id} name={tag.name} content={tag.content} />
        );
      })}
    </Helmet>
  );
};
