// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import Icon from 'Icons';

import usePortal from 'react-hooks/usePortal';
import useComponent from 'react-hooks/useComponent';
import useAuth from 'react-hooks/useAuth';

import Avatar from 'Shared-UI/Avatar';
import Dropdown from 'Shared-UI/Dropdown';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';

import Nav from 'UI/Nav';

import { linksArray } from './utils';
import useTheme from 'react-hooks/useTheme';

function Header(): React.Node {
  const { user, logOut } = useAuth();
  const [dropdown, setDropdown] = useState(false);
  const { darkMode } = useTheme();

  const { Portal } = usePortal();
  const { toggleClasses, toggle } = useComponent();

  const links = linksArray(toggle, dropdown);

  useEffect(() => {
    const root = document.body;

    // Define the full class string for both themes
    const darkModeClass =
      'bg-navy-900 text-gray-50 transition-background duration-2000 scrollbar--dark';
    const lightModeClass =
      'bg-gray-50 text-navy-700 transition-background duration-2000 scrollbar--light';

    // Set the appropriate class string based on the darkMode state

    const toggleRoot = (theme: string) => {
      if (root != null) {
        root.className = theme;
      }
    };

    if (!dropdown && darkMode) {
      toggleRoot(darkModeClass);
    }

    if (!dropdown && !darkMode) {
      toggleRoot(lightModeClass);
    }
  }, [darkMode, dropdown]);

  const handleToggleMenu = (e: SyntheticMouseEvent<HTMLElement>): void => {
    e.preventDefault();
    setDropdown(!dropdown);
  };

  async function handleSignOut(
    e: SyntheticMouseEvent<HTMLElement>
  ): Promise<void> {
    e.preventDefault();

    const logOutUser = () => {
      if (logOut) {
        return logOut();
      } else {
        console.error('Log Out is undefined');
      }
    };

    try {
      await logOutUser();
      console.log('Success!');
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <div className='header-wrapper py-3 flex flex-row justify-center items-center w-full lg:py-7'>
        <div
          className={`header flex flex-row items-center justify-between gap-2 border p-1 w-full rounded-full md:gap-7 md:w-fit ${toggleClasses(
            'border-gray-600',
            'border-navy-600'
          )}`}
        >
          <div
            className={`header__avatar rounded-full md:p-0.5 ${toggleClasses(
              'bg-brand-500',
              'bg-brand-300'
            )}`}
          >
            <a href='/'>
              <Avatar height={40} width={40} />
            </a>
          </div>
          <div className='header__nav'>
            <Nav links={links} />
          </div>
          <div className='header__cta flex flex-row gap-2'>
            {user ? (
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                icon={{ isTrailing: false, isLeading: false }}
                text='Log Out'
                button={{ onClick: (e) => handleSignOut(e) }}
              />
            ) : (
              <Button
                variant={BUTTON_VARIANTS.SECONDARY}
                icon={{ isTrailing: false, isLeading: false }}
                text='Log In'
                link={{ to: '/login' }}
              />
            )}

            <Button
              variant={BUTTON_VARIANTS.ICON}
              icon={{
                isLeading: true,
                feather: true,
                icon: { leading: 'menu' },
              }}
              text={null}
              button={{ onClick: (e) => handleToggleMenu(e) }}
            />
          </div>
        </div>
      </div>
      <Portal
        children={
          <>
            {dropdown ? (
              <div className='dropdown-wrapper absolute top-12 right-4'>
                <Dropdown links={links} />
              </div>
            ) : null}
          </>
        }
        root={document.body}
      />
    </>
  );
}

export default Header;
