// @flow
import type { ContentType } from 'UI/Section/Information'
import type { FactType, LinkType } from 'UI/Section/Scenario'

export const facts: Array<FactType> = [
  {
    label: 'Role',
    role: 'UX/UI Designer',
  },
  {
    label: 'Duration',
    role: '8 Weeks',
  },
  {
    label: 'Project',
    role: 'Self-Ordering Coffee App',
  },
];

export const links: Array<LinkType> = [
  {
    id: 0,
    url: '',
    title: 'Explore Full Case Study',
  },  
  {
    id: 1,
    url: '',
    title: 'Live Demo',
  },
];

export const improvements: Array<ContentType> = [
  {
    id: 0,
    value:
      'Digital prototyping tools would have been more efficient than hand-drawn paper prototypes.',
  },
  {
    id: 1,
    value:
      'Future research should include recorded interviews to capture detailed insights during contextual inquiry.',
  },
  {
    id: 2,
    value:
      'The solution needs to enhance, not replace, the vital customer-barista relationship',
  },
  {
    id: 3,
    value:
      'Limited usability testing due to business circumstances - project paused until permanent location is secured',
  },
  {
    id: 4,
    value:
      'Questions about app necessity at current business stage - need to balance implementation costs against customer experience benefits',
  },
];

export const keylessons: Array<ContentType> = [
  {
    id: 0,
    value:
      'Record interviews (with permission) alongside taking notes to capture detailed customer feedback and insights. ',
  },
  {
    id: 1,
    value:
      'Design the app to complement barista interactions: Users place and pay for orders through the app, then show a code to the barista who prepares their drink. This preserves the valuable personal connection while adding convenience.',
  },
  {
    id: 2,
    value:
      'Conduct moderated usability testing with 5-8 participants to evaluate screen flows and interactions. This would help identify pain points and validate the long-term viability of the concept.',
  },
  {
    id: 3,
    value:
      'Conduct additional research to evaluate the proof of concepts value proposition, considering implementation costs, operational efficiency, and long-term feasibility.',
  },
  {
    id: 4,
    value:
      'Develop and test multiple low-fidelity prototypes to gather comprehensive user feedback before proceeding with final design iterations.',
  },
];
