// @flow
import bubbly_img from 'assets/bubbly_thumbnail.mp4';
import bubbly_img_night from 'assets/bubbly__thumbnail--dark.mp4';
import steadypaycheck_img from 'assets/steadypaycheck__thumbnail.mp4';
import steadypaycheck_img_night from 'assets/steadypaycheck__thumbnail--dark.mp4';
import techrehab_img from 'assets/techrehab__thumbnail.svg';
import techrehab_img_night from 'assets/techrehab__thumbnail.svg';
import ncoffee from 'assets/ncoffee.png';
import ncoffeedark from 'assets/ncoffee--dark.svg';

type ClassNameType = {
  light: string,
  dark: string,
};

type ColType = {
  md?: number,
  xl?: number,
};

type MediaType = {
  type: string,
  src: string,
  srcDark: string,
};

type ButtonType = {
  text: string,
  link: string,
  width: string,
  imgSize?: string,
  imgClass?: string,
};

type ImgSize = {
  width: string,
  height: string,
};

export type ProjectType = {
  id: number,
  category: string,
  className: ClassNameType,
  col: ColType,
  media: MediaType,
  button?: ButtonType,
  imgClass?: string,
  imgSize?: ImgSize,
};

export const projects: Array<ProjectType> = [
  {
    id: 0,
    category: 'Dashboards',
    className: {
      light: '',
      dark: '',
    },
    col: {
      md: 2,
      xl: 4,
    },
    media: {
      type: 'video',
      src: bubbly_img,
      srcDark: bubbly_img_night,
    },
    button: {
      text: 'Bubbly',
      link: '/bubbly',
      width: '100px',
    },
  },
  {
    id: 1,
    category: 'Mobile',
    className: {
      light: '',
      dark: '',
    },
    col: {
      md: 2,
    },
    media: {
      type: 'video',
      src: steadypaycheck_img,
      srcDark: steadypaycheck_img_night,
    },
    button: {
      text: 'Steady PayCheck',
      link: '/steadypaycheck',
      width: '180px',
    },
  },
  {
    id: 2,
    category: 'Websites',
    className: {
      light: 'bg-blue-400 ',
      dark: '',
    },
    col: {
      md: 2,
      xl: 2,
    },
    media: {
      type: 'img',
      src: techrehab_img,
      srcDark: techrehab_img_night,
    },
    button: {
      text: 'Techrehab',
      link: '/techrehab',
      width: '130px',
    },
  },
  {
    id: 3,
    category: 'Mobile',
    className: {
      light: 'bg-gray-500 xl:row-span-2',
      dark: 'bg-navy-500 xl:row-span-2',
    },
    imgClass: 'flex items-center justify-center',
    imgSize: {
      width: 'auto',
      height: 'auto',
    },
    col: {
      md: 2,
      xl: 4,
    },
    media: {
      type: 'img',
      src: ncoffee,
      srcDark: ncoffeedark,
    },
    button: {
      text: 'N.Coffee',
      link: '/ncoffee',
      width: '130px',
    },
  },
];
