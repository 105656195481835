// @flow
import * as React from 'react';
import useComponent from 'react-hooks/useComponent';

import Badge, { BADGE_VARIANTS } from 'Shared-UI/Badge';
import Button, { BUTTON_VARIANTS } from 'Shared-UI/Button';
import type { ButtonType } from "Shared-UI/Button";
import type { ProjectType, BadgeType, MouseEventHandler } from ".";

type CaseStudyProps = {
  render: any, // sort out in pages & layouts branch
  project: ProjectType,
  hovered?: boolean,
  onMouseEnter?: MouseEventHandler,
  onMouseLeave?: MouseEventHandler,
}

type CaseStudyInProgressProps = {
  badge?: BadgeType,
  project?: ProjectType
}

export function CaseStudy({
  render,
  project,
  hovered,
  onMouseEnter,
  onMouseLeave,
}: CaseStudyProps): React.Node {
  return (
    <div
      className={`project relative h-full overflow-hidden rounded-[inherit] ${project.imgClass && project.imgClass}`}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={`project_image ${project.imgSize ? `${project.imgSize.width}-full ${project.imgSize.height}-full` : 'w-full h-full'}`}>
        {render}
      </div>
      {project?.button?.text && (
        <Button
          variant={BUTTON_VARIANTS.ICON}
          link={{
            to: project?.button?.link,
          }}
          text={project?.button?.text}
          hovered={hovered}
          defaultWidth={project?.button?.width}
          arrow
        />
      )}
    </div>
  );
}

export function CaseStudyInProgress({ badge, project }: CaseStudyInProgressProps): React.Node  {
  const { toggle } = useComponent();

  return (
    <div className='project flex flex-col justify-center items-center h-full px-6 pt-9 pb-8'>
      <div className='project_image flex flex-col justify-center items-center h-full'>
        <picture>
          <img
            className='w-full h-full object-cover object-center'
            src={toggle(
              project?.media.src || '',      // Fallback to an empty string if undefined
              project?.media.srcDark || ''   // Fallback to an empty string if undefined
            )}
            alt={project?.media.alt}
          />
        </picture>
      </div>

      <Badge
        variant={BADGE_VARIANTS.MESSAGE}
        label={badge?.label}
        message={badge?.message}
        icon={{
          feather: true,
          icon: 'circle',
          fill: toggle('var(--navy-400)', 'var(--gray-50)'),
        }}
      />
    </div>
  );
}
