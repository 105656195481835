import Layout, { LAYOUT_VARIANTS } from 'Layout';
import { HERO_VARIANTS } from 'UI/Hero';
import { facts, links, keylessons, improvements } from './utils';
import { Container } from 'Grid';
import useComponent from 'react-hooks/useComponent';
import { ncoffeetags } from 'Pages';

import withProtected from 'hoc/withProtected';

import Section, {
  SECTION_VARIANTS,
  INFORMATION_VARIANTS,
  MEDIA_VARIANTS,
} from 'UI/Section';
import { HEADING_VARIANTS } from 'Shared-UI/Headings';

import UserJourney from 'assets/NCoffee/user_journey--light.jpg';
import UserPersona from 'assets/NCoffee/user_persona__amberbushnell--light.jpg';
import HeroWallpaper from 'assets/NCoffee/ncoffee_hero_wallpaper--light.png';
import Wireframes from 'assets/NCoffee/wireframes.jpg';
import PrototypeThumbnail from 'assets/NCoffee/prototype.png';
import NCoffeePrototype from 'assets/NCoffee/prototype.mp4';
import WireframesWallpaper from 'assets/NCoffee/wireframes_wallpaper.png';

import HeroWallpaperDark from 'assets/NCoffee/nightmode/ncoffee_hero_wallpaper--dark.png';
import UserPersonaDark from 'assets/NCoffee/nightmode/user_persona__amberbushnell--dark.jpg';
import UserJourneyDark from 'assets/NCoffee/nightmode/user_journey--dark.jpg';

function NCoffee() {
  const { toggle, toggleClasses } = useComponent();

  return (
    <Layout
      variant={LAYOUT_VARIANTS.PROJECT}
      meta={{
        title: 'N.Coffee: Coffee ordering app for on the go, coffee lovers',
        tags: ncoffeetags,
      }}
      project={{
        name: 'ncoffee',
      }}
      hero={{
        variant: HERO_VARIANTS.PRIMARY,
        title: (
          <>
            Coffee ordering app for on the go <br /> coffee lovers
          </>
        ),
        subtitle: 'N.Coffee',
        project: {
          img: { src: toggle(HeroWallpaper, HeroWallpaperDark) },
        },
        className: `radius-2xl ${toggleClasses(
          'bg-[#F6F7F9]',
          'bg-[#20232A]'
        )}`,
      }}
      scenario={{
        content: {
          paragraph:
            'N.Coffee a mobile coffee barista service in Chichester, faces challenges in providing a seamless ordering experience for its on-the-go customers due to the lack of digital tools and a fixed location. The business aims to create a product that allows customers to conveniently place orders and collect their coffee from various mobile locations. ',
          facts: facts,
          links: [],
        },
      }}
    >
      <Container>
        <div className='discover-section flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Identifying our insights & key findings',
                subtitle: 'Discover',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              content: {
                title: 'Insights & Findings',
                paragraph:
                  'Insights for N.Coffee, a mobile coffee barista service, reveal a customer base primarily composed mainly of gym-goers and commuters. Customers value personalised interactions with baristas, suggesting that digital solutions should enhance rather than replace human interaction. Popular coffees were Flat Whites and Lattes. Payment preferences lean towards contactless methods (60%), with card contactless (40%) and Apple Pay (30%) being popular with cash still used by some customers (20%). These insights indicate a need for a flexible, user-friendly app that caters to fitness enthusiasts, offers quick service, focuses on popular drink options, and provides diverse payment methods while maintaining the personal touch that customers appreciate⁠.',
              },
            }}
          />
        </div>
      </Container>

      <Container>
        <div className='define flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.HEADING}
            heading={{
              headings: {
                heading: HEADING_VARIANTS.H2,
                title: 'Understanding User Needs, Pain Points & User Problems',
                subtitle: 'Define',
              },
              showSubtitle: true,
              className:
                'text-left md:[&>*]:text-center [&>*]:xl:w-96 [&>*]:xl:m-auto',
            }}
          />
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              content: {
                title: 'Personas',
                paragraph:
                  'Personas create a realistic representation of the target users, helping understand and empathise with the needs, behaviours, and goals of typical N.Coffee customers.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: toggle(UserPersona, UserPersonaDark),
                  alt: 'Andrew Barn Smith - User Persona',
                },
              },
              className: `overflow-hidden p-10 [&>*]:w-2/3`,
              animated: true,
              rounded: true,
            }}
          />

          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              content: {
                title: 'User Journey',
                paragraph:
                  'This user journey map illustrates a gym-goers coffee ordering experience. The customer finishes their workout, orders, pays, and receives their beetroot latte. Throughout the journey, it highlights the customers thoughts, emotional experiences, key touchpoints, and potential opportunities for service improvement at each phase.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: toggle(UserJourney, UserJourneyDark),
                  alt: 'Andrew Barn Smith - User Journey',
                },
              },
              className: `overflow-hidden p-10 [&>*]:w-2/3`,
              animated: true,
              rounded: true,
            }}
          />
        </div>
      </Container>
      <Container>
        <div className='design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              content: {
                title: 'Wireframes',
                paragraph:
                  'Here is low-fidelity visual representations examples of how the user would interact with the N.Coffee mobile application.',
              },
            }}
          />
          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.IMAGE,
              media: {
                img: {
                  src: Wireframes,
                  alt: 'Andrew Barn Smith - User Journey',
                },
              },
              className: `overflow-hidden p-10 [&>*]:w-2/3`,
              animated: true,
              rounded: true,
            }}
          />
        </div>
      </Container>
      <Container>
        <div className='design flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.PARAGRAPH,
              content: {
                title: 'Paper Prototyping',
                paragraph:
                  'This video demonstrates an early-stage paper prototype of the N.Coffee application. The prototype was created to test and visualise screen transitions and user interactions. ',
              },
            }}
          />

          <Section
            variant={SECTION_VARIANTS.MEDIA}
            media={{
              variant: MEDIA_VARIANTS.VIDEO,
              media: {
                video: {
                  source: {
                    src: NCoffeePrototype,
                    alt: 'NCoffee - Paper Prototype',
                  },
                  videoKey: 'prototype',
                  type: 'video/mp4',
                  autoPlay: true,
                  muted: true,
                  controls: true,
                  poster: PrototypeThumbnail,
                },
              },
              className: `w-full [&>*]:rounded-3xl ${toggleClasses(
                '[&>*]:bg-[#F6F7F9]',
                '[&>*]:bg-[#20232A]'
              )}`,
              animated: true,
            }}
          />
        </div>
      </Container>
      <Container>
        <div className='summary flex flex-col gap-7 lg:gap-9'>
          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.NUMBER,
              contents: improvements,
              content: {
                title: 'Improvements',
              },
            }}
          />

          <Section
            variant={SECTION_VARIANTS.INFORMATION}
            information={{
              variant: INFORMATION_VARIANTS.NUMBER,
              contents: keylessons,
              content: {
                title: 'Key Lessons',
              },
            }}
          />
        </div>
      </Container>
    </Layout>
  );
}

export default (withProtected(NCoffee): React.ComponentType<{}>);
